$dark: rgb(18,18,18);
$grey: rgb(194, 194, 194);
$smokeWhite: whitesmoke;
$green: #4EBF56;
$white: white;
$lightgreen: #BBF2BE;

.btn {
    border: none;
}
